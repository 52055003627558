import React from "react"
import PropTypes from "prop-types"
import TransitionLinkFade from "../TransitionLink/TransitionLinkFade"

const Signature = ({ name, text, className = "", link }) => {
  if (name === null && text === null) return null

  const Text = ({ text, link, className }) => {
    if (link && link.url) {
      if (link.type === "entry") {
        return (
          <TransitionLinkFade className="block" to={`/${link.element?.uri}`}>
            <div className={className}>{text}</div>
          </TransitionLinkFade>
        )
      } else {
        return (
          <a className="block" href={link.url} target="_blank" rel="noreferrer">
            <div className={className}>{text}</div>
          </a>
        )
      }
    }

    return <div className={className}>{text}</div>
  }
  return (
    <div className={`space-y-2 ${className}`}>
      {name !== null && (
        <h5 className="font-serif italic text-xl leading-none whitespace-pre-wrap">
          {name}
        </h5>
      )}
      {text && (
        <Text
          className="font-normal text-sm whitespace-pre-wrap"
          text={text}
          link={link}
        />
      )}
    </div>
  )
}

Signature.propTypes = {
  name: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
}

export default Signature

import React from "react"

const RichText = ({ data }) => {
  const body = data.body
  return (
    <div
      className="rte xl:max-w-3xl xl:mx-auto py-14"
      dangerouslySetInnerHTML={{ __html: body }}
    />
  )
}

export default RichText

import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/Layout/Layout"
import Hero from "../components/Hero/Hero"
import RevealWrapper from "../components/RevealWrapper/RevealWrapper"
import RichText from "../components/FlexibleContent/RichText/RichText"

const BasicPageTemplate = ({ data, location }) => {
  const {
    metaTitle,
    metaDescription,
    title,
    theme,
    heroHeading,
    flexibleContentBasic,
  } = data.craftPagesBasicEntry

  const renderFlexibleContent = item => {
    switch (item.typeHandle) {
      case "image":
        let imageData = {}
        if (item.image.length) {
          imageData = {
            image: getImage(item.image[0].localFile),
            alt: item.image[0].title,
          }
        }

        return (
          <RevealWrapper>
            <GatsbyImage
              className="w-full"
              image={imageData.image}
              alt={imageData.alt}
            />
          </RevealWrapper>
        )
      case "richText":
        return <RichText data={item} />
      default:
        return <></>
    }
  }

  return (
    <Layout
      title={metaTitle || title}
      description={metaDescription}
      theme={theme}
      location={location}
    >
      <div className="js-transition-link-target" data-theme={theme}>
        <Hero
          heroStyle="tier3"
          heroHeading={heroHeading || title}
          theme={theme}
        />
        {flexibleContentBasic.length > 0 && (
          <div className="mx-ogs py-16 lg:py-24 2xl:py-32 grid md:grid-cols-12 md:gap-x-gs">
            <div className="md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3 space-y-12 lg:space-y-20">
              {flexibleContentBasic.map((item, index) => {
                return (
                  <div key={`${item.id} ${index}`}>
                    {renderFlexibleContent(item)}
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default BasicPageTemplate

export const query = graphql`
  query BasicPageQuery($slug: String) {
    craftPagesBasicEntry(slug: { eq: $slug }) {
      title
      heroHeading
      theme
      metaTitle
      metaDescription
      flexibleContentBasic {
        ... on Craft_flexibleContentBasic_richText_BlockType {
          typeHandle
          id
          body
        }
        ... on Craft_flexibleContentBasic_image_BlockType {
          typeHandle
          id
          image {
            ...imageFragment
          }
        }
      }
    }
  }
`

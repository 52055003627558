import React, { useState, useRef, useEffect } from "react"
import { CSSTransition } from "react-transition-group"

const RevealWrapper = ({
  children,
  className = "",
  tag = "div",
  innerClassName,
  threshold = "0.25",
  delay = "0",
}) => {
  const [isVisible, setVisible] = useState(false)

  const wrapperRef = useRef()
  const Wrapper = tag

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          setTimeout(() => {
            setVisible(true)
            observer.disconnect()
          }, delay)
        }
      },
      {
        threshold: threshold,
      }
    )

    observer.observe(wrapperRef.current)

    return () => observer.disconnect()
  }, [threshold, delay])

  return (
    <Wrapper ref={wrapperRef} className={className}>
      <CSSTransition in={isVisible} timeout={1100} classNames="fade-in-up">
        <div className={`fade-in-up-start ${innerClassName}`}>{children}</div>
      </CSSTransition>
    </Wrapper>
  )
}

export default RevealWrapper
